<template>
<v-container>
  <div>
    <navbar title="سفارش های من" route = '/panel'/>
    <v-main>
    <tab/>
  </v-main>
  </div>
</v-container>
</template>

<script>
import navbar from '@/components/navbar/appBar'
import tab from './components/tabMenu/tabMenu'
import { getFactors } from './models/factors'
export default {
   components: {
      navbar,
      tab
   },
   created () {
     getFactors()
   }
}
</script>