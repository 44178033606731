<template>
  <div>
    <v-card class="card flat-card" color="surface">
     <v-tabs fixed-tabs v-model="tab" background-color="surface" class="primaryText--text">
      <v-tab class="mx-auto">فعال</v-tab>
      <v-tab class="mx-auto">قبلی</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div>
          <accordion v-if="activeFactors.length != 0" :factors= 'activeFactors'/>
          <div class="d-flex flex-column align-center pa-3" v-if="activeFactors.length == 0" >
          <v-img width="80" class="my-3 mx-auto" src="@/assets/icon/shopping-list.svg"></v-img>
          <span class="medium-font onSurface--text">موردی یافت نشد!</span>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <accordion v-if="resiveFactors.length != 0" :factors= 'resiveFactors' />
        <div class="d-flex flex-column align-center pa-3" v-if="resiveFactors.length == 0">
        <v-img width="80" class="my-3 mx-auto" src="@/assets/icon/shopping-list.svg"></v-img>
        <span class="medium-font onSurface--text">موردی یافت نشد!</span>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  </div>
</template>
<script>
import accordion from '../accordion/orderAccordion'
import { mapState } from 'vuex'
  export default {
    components:{
      accordion
    },
    data: () => ({
      tab: null
    }),
    computed: {
      ...mapState({
        activeFactors: state => state.factors.factors.filter(item => item.status != 6 && item.return_status != 2),
        resiveFactors: state => state.factors.factors.filter(item => item.status == 6 || item.return_status == 2)
      })
    },
    methods: {
      show () {
        console.log(this.factors)
      }
    }
  }
</script>